:root {
  --primary-bg: #242526;
  --secondary-bg: #fff;
  --primary-text-color: #555;
  --secondary-text-color: #cecece;
  --border-radius: 8px;
  --speed: 500ms;
}

.dropdown-trigger img{
  position: absolute;
  top: 10px;
  right: 20px;
  height: 40px;
  width: 40px;
  overflow: hidden;
  cursor: pointer;
  background-color: #fff;
}

.dropdown-menu{
  position: absolute;
  top: 60px;
  right: 20px;
  background-color: #fff;
  border-radius: var(--border-radius);
  padding: 10px 20px;
  width: 200px;
}

.dropdown-menu::before{
  content: '';
  position: absolute;
  top: -5px;
  right: 20px;
  height: 20px;
  width: 20px;
  background: var(--secondary-bg);
  transform: rotate(45deg);
}

.dropdown-menu.active{
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  transition: var(--speed) ease;
}

.dropdown-menu.inactive{
  opacity: 0;
  visibility: hidden;
  transform: translateY(-70px);
  transition: var(--speed) ease;
}

h3{
  width: 100%;
  text-align: center;
  font-size: 18px;
  padding: 5px 0;
  font-weight: 500;
  color: var(--primary-text-color);
  line-height: 1.2rem;
}

h3 span{
  font-size: 14px;
  color: var(--secondary-text-color);
  font-weight: 400;
}

.dropdown-menu ul li{
  padding: 10px  0;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}

.dropdown-menu ul li:hover a{
  color: rgb(212, 33, 9);
  cursor: pointer;
}

.dropdown-menu ul li:hover img{
  opacity: 1;
  cursor: pointer;
}

.dropdownItem{
  display: flex;
  margin: 10px auto;
}

.dropdownItem img{
  max-width: 20px;
  margin-right: 10px;
  opacity: 0.5;
  transition: var(--speed);
}

nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  background-color: #ffffff; /* Dostosuj kolor tła nawigacji */
  z-index: 1000; /* Upewnij się, że nawigacja jest na wierzchu */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Dodaj cień dla efektu */
}

.OutletWrapper {
  margin-top: 60px; 
}

.Layout .logo{
  top: 10px;
  height: 60px;
  padding: 5px;
}
.Layout nav ul {
  position: relative;
  margin: 0;
  padding: 0;
  list-style-type: none;
  /*  */
  align-items: center;
  padding: 0.5rem;
  font-weight:normal;
}

.Layout nav {
  display: flex;
}

.line {
  display: flex;
}

.link {
  padding: 10px;
  font-size: 1.2rem;
}

.Layout nav button {
  padding: 0.7rem 1.7rem;
  font-weight: bold;
}

.Layout nav button:hover {
  box-shadow: 0px 0px 5px rgb(255, 255, 255);
}

.Layout nav select:hover {
  box-shadow: 0px 0px 5px rgb(255, 255, 255);
}

.Layout nav input:hover {
  box-shadow: 0px 0px 5px rgb(255, 255, 255);
}