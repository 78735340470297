* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(255, 255, 255);
}

button, select {
  padding: 0.2rem 1rem;
  margin: 0.1rem;
  font-weight: bold;
  color: black;
  text-transform: uppercase;
  outline: rgb(99, 151, 254);
  border-radius: 5px;
  cursor: pointer;
  background: transparent;
  /* background-color: rgb(121, 44, 44); */
}

button:hover, select:hover {
  border-color: rgb(99, 151, 254);
}

a {
  text-decoration: none;
  color: inherit;
}

.EmployeeTable, .EquipmentTable {
  padding: 0.5rem;
}

table {
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}

table tbody tr {
  background-color: rgb(242, 242, 242);
}

table tbody tr:nth-child(even) {
  background-color: rgb(221, 221, 221);
}

table td,
table th {
  padding: 0.2rem;
}

table button {
  padding: 0.2rem 1rem;
  margin: 0.1rem;
}

form {
  display: grid;
  font-size: 1.2rem;
}

.control {
  width: 100%;
  padding: 1rem;
  display: grid;
  gap: 0.2rem;
}

.buttons {
  font-size: 1.2rem;
  padding: 1rem;
}

.control input,
.control select {
  font-size: 1.2rem;
  padding: 0.2rem;
}

.control .checkbox,
.control .radio {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  padding: 0.2rem;
  cursor: pointer;
}

button, select {
  padding: 0.7rem 1.7rem;
  margin-right: 1rem;
}

.link {
  flex: 1;
}
